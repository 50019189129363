import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels, usePrefs, useRequestData } from 'wsm-common-data';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { g } from '../../global-constants';
import useFocusTrap from '../../hooks/modern/useFocusTrap';
import InputDisplay from './InputDisplay';
import VehicleCards from './VehicleCards';
import SearchLinks from './SearchLinks';
import RichContent from './richContent/RichContent';
import NoResults from './NoResults';
import RecentSearches from './RecentSearches';
import shouldShowRecentSearches from '../../util/modern/shouldShowRecentSearches';
import hasEmptyResults from '../../util/modern/hasEmptyResults';
import hasResults from '../../util/modern/hasResults';

const FlyOut = ({
	isOpen,
	onClose,
	dropDownRef = React.createRef(),
	inputElementRef = React.createRef(),
	openButtonRef,
	closeModal,
	handleOnChangeInput,
	searchTerm,
	inventoryResults,
	handleLinkClick,
	addInfo,
	richContentToDisplay,
	inputRef,
	numberOfCharactersForFirstSearchNumber,
	pageResults,
	recentSearches,
	handleClearHistory,
	onRemove,
	onReorder,
	handleChipClick,
	handleSubmission,
	onClear
}) => {
	const labels = useLabels();
	const chipAriaLabel = labels.get('SEARCH');
	const removeAriaLabel = labels.get('REMOVE');
	const { showInventoryResults, showPageSearchResults } = usePrefs();
	const [localIsOpen, setLocalIsOpen] = useState(false);
	const { windowId } = useRequestData();

	const { pageAlias } = useSelector((state) => state.widgetData);

	const { suggestions = undefined, vehicles = undefined } =
		inventoryResults || {};
	const isSearchTermLongEnough =
		searchTerm?.length >= numberOfCharactersForFirstSearchNumber;

	const hasResultsLocal = hasResults(
		pageResults,
		suggestions,
		vehicles,
		richContentToDisplay
	);

	const hasEmptyResultsLocal = hasEmptyResults(
		pageResults,
		suggestions,
		vehicles
	);

	const classNames = [
		'modern-search-flyout',
		'box',
		'p-4',
		localIsOpen && 'slide-left'
	];

	const closeClassNames = [
		'btn-unstyled text-link-muted',
		'py-0',
		'px-0',
		'my-3',
		'border-neutral-0-alpha-3',
		'float-right'
	];

	useFocusTrap(
		[openButtonRef, dropDownRef],
		null,
		openButtonRef,
		isOpen,
		closeModal
	);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setLocalIsOpen(true);
		}, 50);

		if (isOpen) {
			document.body.style.overflow = 'hidden';
			inputElementRef.current?.focus();
		}

		return () => {
			clearTimeout(timeoutId);
		};
	}, [isOpen, inputElementRef]);

	const flyOutContent = (
		<>
			{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
			<div
				className="modern-search-modal clearfix"
				role="dialog"
				data-testid="modern-flyout-background"
				onClick={() => onClose(g.CLOSE_BUTTON)}
			/>
			<div
				className={setClassNames(classNames)}
				aria-labelledby="search-modal-headline"
				ref={dropDownRef}
				tabIndex="-1"
				data-testid="modern-flyout"
			>
				<div className="pl-0 py-3 pr-0 clearfix">
					<h2
						className="ddc-heading-3 font-weight-bold text-capitalize float-left mt-3"
						id="search-modal-headline"
					>
						{labels.get('SEARCH')}
					</h2>
					<button
						type="button"
						tabIndex="0"
						aria-label={labels.get('CLOSE_MODAL')}
						className={setClassNames(closeClassNames)}
						data-testid="flyout-close-button"
						onClick={() => onClose(g.CLOSE_BUTTON)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								onClose(g.CLOSE_BUTTON);
								setTimeout(() => {
									openButtonRef.current?.focus(); // Focus on the button after a delay
								}, 10);
							}
						}}
					>
						<i
							className="ddc-icon ddc-icon-remove2 icon-size-2"
							aria-hidden="true"
						/>
					</button>
				</div>

				<InputDisplay
					inputRef={inputRef}
					inputElementRef={inputElementRef}
					isOpen={isOpen}
					onClear={onClear}
					type={g.INPUT}
					data-testid="modern-input"
					handleSubmission={handleSubmission}
					searchTerm={searchTerm}
					handleOnChangeInput={handleOnChangeInput}
				/>
				<div className="mt-6 clearfix">
					{isSearchTermLongEnough &&
						!hasResultsLocal &&
						hasEmptyResultsLocal && (
							<NoResults searchTerm={searchTerm} />
						)}

					{shouldShowRecentSearches(
						searchTerm,
						numberOfCharactersForFirstSearchNumber,
						hasResultsLocal,
						hasEmptyResultsLocal,
						recentSearches
					) && (
						<RecentSearches
							recentSearches={recentSearches}
							handleClearHistory={handleClearHistory}
							chipAriaLabel={chipAriaLabel}
							removeAriaLabel={removeAriaLabel}
							onRemove={onRemove}
							onReorder={onReorder}
							handleChipClick={handleChipClick}
						/>
					)}
					{isSearchTermLongEnough && hasResultsLocal && (
						<div>
							<VehicleCards
								vehicles={vehicles}
								handleLinkClick={handleLinkClick}
							/>
							<SearchLinks
								type="inventory"
								showResults={showInventoryResults}
								data={suggestions}
								iconClass="ddc-icon-single-vehicle"
								headingLabel={labels.get(
									'INVENTORY_SEARCH_RESULTS'
								)}
								ariaLabel={labels.get(
									'INVENTORY_SEARCH_RESULTS'
								)}
								handleLinkClick={handleLinkClick}
							/>
							<SearchLinks
								type="page"
								showResults={showPageSearchResults}
								data={pageResults}
								iconClass="ddc-icon-arrow-circle-right"
								headingLabel={labels.get('PAGE_SEARCH_RESULTS')}
								ariaLabel={labels.get('PAGE_SEARCH_RESULTS')}
								handleLinkClick={handleLinkClick}
							/>
							<RichContent
								addInfo={addInfo}
								richContentToDisplay={richContentToDisplay}
								pageAlias={pageAlias}
								windowId={windowId}
								handleLinkClick={handleLinkClick}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);

	return createPortal(flyOutContent, document.body);
};

FlyOut.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	dropDownRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	inputElementRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	openButtonRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	closeModal: PropTypes.func,
	handleOnChangeInput: PropTypes.func,
	searchTerm: PropTypes.string,
	inventoryResults: PropTypes.shape({}),
	handleLinkClick: PropTypes.func,
	addInfo: PropTypes.shape(),
	richContentToDisplay: PropTypes.arrayOf(PropTypes.string),
	pageAlias: PropTypes.string,
	inputRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	numberOfCharactersForFirstSearchNumber: PropTypes.number,
	pageResults: PropTypes.arrayOf(PropTypes.shape({})),
	recentSearches: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			id: PropTypes.string
		})
	),
	handleClearHistory: PropTypes.func,
	onRemove: PropTypes.func,
	onReorder: PropTypes.func,
	handleChipClick: PropTypes.func,
	onClear: PropTypes.func,
	handleSubmission: PropTypes.func
};

export default FlyOut;
